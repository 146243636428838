import request from '@/utils/request'

export default {
    // 早鸟海报生成
    poster(params, headers) {
        return request({
            url: '/share/user/2022/zn/poster',
            method: 'get',
            params,
            headers: headers
        })
    },
}
